import React, { useState } from 'react';
// Load views for different brands
//////////////////////
///////////////////////////////////////////////////////////////////////////////////////
/////////
import styles from './offers-tile.module.scss'; // eslint-disable-line no-redeclare
//////////
import cx from '../../../../utils/classnames';
import { PointsAndMoney } from '../../rewards-helper-components';
import { CtaTextLink } from '../../../../components/common/cta/cta';
import ServiceHandler from '../../../../services/service-handler';
import { useNavigateTo } from '../../../../hooks/use-navigateTo';
import AreYouSure from '../../../../components/common/are-you-sure/are-you-sure';
import { launchExternalLink } from '../../../../components/common/dangerous-html/dangerous-html';
export function OffersTileContents(_a) {
    var _b;
    var rewardOffer = _a.rewardOffer, _c = _a.desktopCTATitle, desktopCTATitle = _c === void 0 ? '' : _c, parameters = _a.parameters, className = _a.className, redeemed = _a.redeemed, offers = _a.offers;
    var _d = useState(false), showSpeedbump = _d[0], setShowSpeedbump = _d[1];
    var cfg = ServiceHandler.ConfigService;
    var navigateTo = useNavigateTo().navigateTo;
    var onContinue = function () {
        setShowSpeedbump(false);
        return launchExternalLink(rewardOffer.dashboardCTAExternalTarget);
    };
    var onClick = function () {
        if (rewardOffer.dashboardCTAAction == 'ExternalRedirect') {
            return setShowSpeedbump(true);
        }
        if (rewardOffer.dashboardCTAExternalTarget)
            return launchExternalLink(rewardOffer.dashboardCTAExternalTarget);
        navigateTo('/rewards/detail', "rewardCode=".concat(rewardOffer.rewardCode));
    };
    var pricePlan = rewardOffer.pricePlan[0];
    return (React.createElement(React.Fragment, null,
        showSpeedbump && React.createElement(AreYouSure, { contentFragment: 'leaving-ford', onContinue: onContinue, onCancel: function () { return setShowSpeedbump(false); } }),
        React.createElement("div", { className: cx(styles.tile, className), key: rewardOffer.code, onClick: onClick },
            rewardOffer.quantityAvailable != null && rewardOffer.quantityAvailable < 16 && (React.createElement("div", { className: styles.quantity },
                rewardOffer.quantityAvailable,
                " ",
                ServiceHandler.ConfigService.isEnglish ? 'Remaining' : 'Restante')),
            React.createElement("img", { className: styles.image, src: rewardOffer.dashboardImageURL, alt: rewardOffer.dashboardImageAltTag || '' }),
            rewardOffer.detailsImageOverlayText && React.createElement("div", { className: styles.tierExclusive }, rewardOffer.detailsImageOverlayText),
            React.createElement("div", { className: cx(styles.content, (_b = {}, _b[styles.overlayAdjust] = rewardOffer.detailsImageOverlayText, _b)) },
                React.createElement("div", { className: cx(styles.title), dangerouslySetInnerHTML: { __html: rewardOffer.dashboardTitle } }),
                cfg.isFPR && React.createElement("hr", null),
                +pricePlan.points > 0 || pricePlan.money > 0 ? (React.createElement("div", { className: styles.points },
                    React.createElement(PointsAndMoney, { points: pricePlan.points, money: pricePlan.money }),
                    React.createElement("div", { className: styles.points }))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.points },
                        pricePlan.pointsText,
                        React.createElement("span", null),
                        React.createElement("div", { className: styles.points }, pricePlan.moneyText)))),
                !redeemed && (React.createElement("div", { className: styles.link },
                    React.createElement(CtaTextLink, { id: 'tile', className: styles.link, search: parameters, ariaLabel: desktopCTATitle },
                        React.createElement("span", null, rewardOffer.dashboardCTALabel))))))));
}
