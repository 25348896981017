/* eslint-disable no-redeclare */
import React, { useContext, useEffect, useState } from 'react';
import NavDropdown from '../../common/navDropdown/navDropdown';
import Tab from '../../common/navDropdown/tab';
import { useContent } from '../../../hooks/use-content';
import styles from './nav-bar.module.scss';
import cx from '../../../utils/classnames';
import { isMobileWidth } from '../../../utils/url.utils';
import { useAmplitude } from '../../../hooks/use-amplitude';
import { useNavigateTo } from '../../../hooks/use-navigateTo';
import { isMobile } from '../../../services/mobile-bridge/mobile-bridge';
import { CountrySelector, CountrySelectorSpeedBump } from '../country-selector/country-selector';
import { PointsHeroContext } from '../../../context/points-hero-context';
import { MODE } from '../../../context/rewards-context';
export var NavBar = function () {
    var NAV_BAR_TAB_STATES = {
        //OVERVIEW: '/overview',
        //DASHBOARD: '/dashboard',
        REWARDS: '/rewards',
        REWARDS_EARN: '/rewards',
        REWARDS_REDEEM: '/rewards?',
        HISTORY: '/rewards-history',
        //VISA: '/credit-card',
        FAQS: '/faqs',
    };
    var getValueByTitle = useContent('nav-bar')[0].getValueByTitle;
    var _a = useState(''), activeTab = _a[0], setActiveTab = _a[1];
    var _b = useNavigateTo(), navigateTo = _b.navigateTo, authNavigateTo = _b.authNavigateTo;
    var getAmplitudeScreenTitle = useAmplitude().getAmplitudeScreenTitle;
    var setIsDesktop = useContext(PointsHeroContext)[0].setIsDesktop;
    var _c = useState(false), showCountrySpeedbump = _c[0], setShowCountrySpeedbump = _c[1];
    useEffect(function () {
        setActiveTab(Object.values(NAV_BAR_TAB_STATES).find(function (val) { return window.location.pathname.includes(val); }));
    });
    useEffect(function () {
        setIsDesktop(!isMobileWidth);
        window.addEventListener('resize', function () {
            setIsDesktop(!isMobileWidth);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.navBar },
            React.createElement(NavDropdown, { setTab: function (id, authed) {
                    if (authed === void 0) { authed = false; }
                    setActiveTab(id);
                    if (authed)
                        return authNavigateTo(id);
                    navigateTo(id);
                }, active: activeTab, className: styles.tabBar, ariaLabel: 'navigation tabbar', mobileTitle: getValueByTitle('mobile-title') },
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.REWARDS_EARN, authed: true, modeType: MODE.EARN }, getValueByTitle('rewards')),
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.REWARDS_REDEEM, authed: true, modeType: MODE.REDEEM }, getValueByTitle('redeem-rewards')),
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.HISTORY, authed: true }, getValueByTitle('history')),
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.FAQS }, getValueByTitle('faqs')),
                isMobile() && (React.createElement(Tab, { className: cx(styles.tab), id: '' },
                    React.createElement(CountrySelector, { setOverlay: setShowCountrySpeedbump }))))),
        isMobile() && showCountrySpeedbump && React.createElement(CountrySelectorSpeedBump, { setOverlay: setShowCountrySpeedbump }),
        React.createElement("h1", { className: styles.accessiblily, style: { position: 'absolute' } }, getAmplitudeScreenTitle())));
};
