var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useContent } from '../../../hooks/use-content';
import { CtaButton, CtaSecondaryButton } from '../cta/cta';
import ServiceHandler from '../../../services/service-handler';
import styles from './activate-popup.module.scss';
import { useAmplitude } from '../../../hooks/use-amplitude';
import cx from '../../../utils/classnames';
import { PointsHeroContext } from '../../../context/points-hero-context';
import { useNavigateTo } from '../../../hooks/use-navigateTo';
function ActivatePopup(_a) {
    var onClose = _a.onClose, _b = _a.hasSubText, hasSubText = _b === void 0 ? false : _b;
    var _c = useContent('activate-account-view/c2a-popup')[0], content = _c.content, getValueByTitle = _c.getValueByTitle, getCtaProps = _c.getCtaProps;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var navigateTo = useNavigateTo().navigateTo;
    var cfg = ServiceHandler.ConfigService;
    var memberStatus = useContext(PointsHeroContext)[0].memberStatus;
    var _d = useState(), show = _d[0], setShow = _d[1];
    var theOverlay = useRef();
    useEffect(function () {
        if (memberStatus != null) {
            if (memberStatus == "I") {
                setShow(true);
            }
            else {
                setShow(false);
            }
        }
        if (show || memberStatus == "I") {
            logAmplitudeEvent("modal dialog", {
                title: getValueByTitle('title'),
            });
        }
    }, [content, memberStatus]);
    var onCloseAndTrack = function () {
        logAmplitudeEvent("modal close tapped", {
            title: getValueByTitle('title'),
        });
        setShow(false);
        onClose();
    };
    return show ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.overlay, ref: theOverlay }),
        content && (React.createElement("div", { className: styles.overlayPopupContainer },
            React.createElement("div", { className: styles.overlayPopup },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement("div", { className: styles.closeIcon, onClick: function () { return onCloseAndTrack(); }, style: {
                            color: 'black',
                            cursor: 'pointer',
                            display: 'flex',
                            alignSelf: 'flex-end',
                            flexDirection: 'column',
                            backgroundSize: 'cover',
                            backgroundImage: "url(".concat(cfg.AEM_BASE_URL, "/content/dam/loyalty/").concat(ServiceHandler.ConfigService.BRAND_NAME, "/").concat(cfg.COUNTRY_CODE, "/").concat(cfg.LANGUAGE_COUNTRY_CODE, "/images/closeIcon.svg)"),
                        } }),
                    React.createElement("div", null,
                        React.createElement("div", { className: cx(styles.overlayTitle, styles.activateTitleAreYouSure) }, getValueByTitle('title')),
                        hasSubText && React.createElement("div", { className: styles.overlayCopy }, getValueByTitle('copy')))),
                React.createElement("div", null,
                    React.createElement(CtaButton, __assign({}, getCtaProps('okay button'), { onClick: function () {
                            navigateTo('/activate-account');
                            setShow(false);
                            sessionStorage.setItem("active", 'true');
                        } })),
                    React.createElement(CtaSecondaryButton, __assign({}, getCtaProps('Cancel Button'), { onClick: function () { return setShow(false); } })))))))) : null;
}
export default ActivatePopup;
